<template>
  <v-card flat outlined tile class="flex" min-height="200" :loading="patientUpdating">
  <add-alert-rule-dialog @submit="submitRule" ref="addRule" />
  <v-toolbar flat>
    <span v-if="orgMode" class="font-weight-bold text-h6">Global Patient Alert Rules</span>
    <v-toolbar-title v-else>Patient Alert Rules</v-toolbar-title>
    <v-spacer />
    <v-btn color="primary" text depressed @click="addRule">
      <v-icon left>mdi-file-document-box-plus-outline</v-icon>
      add Rule
    </v-btn>
  </v-toolbar>
  <v-card-text v-if="orgMode" class="row no-gutters">
    <span class="font-weight-light text-body-1">
      Global patient alert rules are applied to the entire patient population. When a patient alert condition is met, the alert will be applied. Rules set on the patient level will take precedence over these global rules.
    </span>
  </v-card-text>
  <v-card-text v-else class="pt-0">
    Patient alert rules are applied to the patient monitoring data entering the chart. When a patient alert condition is met, the alert will be applied. Rule set on the patient level will take precedence over global rules.
  </v-card-text>

  <v-simple-table style="width: 100%; background-color:transparent">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">Metric</th>
          <th class="text-left">Operator</th>
          <th class="text-left">Value</th>
          <th class="text-left">Remove</th>
        </tr>
      </thead>

      <tbody v-if="rulesArray.length > 0">
        <tr v-for="(rule, index) in rulesArray" :key="index">
          <td class="mt-2 mb-2">
            {{ CONSTS.METRICS[rule.metricKind].longName }}
          </td>
          <td>
            {{ rule.operator }}
          </td>
          <td>
            {{ rule.value }}
          </td>
          <td style="max-width: 80px; width: 80px;">
            <v-layout row justify-center>
              <v-btn @click="removeRule(rule)" color="red" icon>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-layout>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
  <v-layout
    class="mx-0 mt-4 mb-4"
    v-if="rulesArray.length === 0"
    style="min-width: 100%; opacity: 0.4"
    row
    justify-center
  >
    No Rules Set
  </v-layout>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import AddAlertRuleDialog from '@/components/dialogs/AddAlertRuleDialog'
import HelperMixin from '@/core/mixins/HelperMixin'
import CONSTS from '@/consts'
import { cToF, kgToLbs } from '@/helpers/conversions'
import clone from 'lodash/clone'

export default {
  mixins: [HelperMixin],
  props: {
    orgMode: Boolean,
  },
  data() {
    return { CONSTS }
  },
  components: {
    AddAlertRuleDialog,
  },
  computed: {
    ...mapState('org', ['org', 'settings']),
    ...mapState('patient', ['patient', 'patientUpdating']),
    rules() {
      return (this.orgMode ? this.org.defaultPatientEventRules : this.patient.rpm.alertRules) || {}
    },
    rulesArray() {
      const rulesArray = []
      for (const [metricKind, rule] of Object.entries(this.rules)) {
        if (rule.above?.value) rulesArray.push({ metricKind, operator: 'Above', value: this.computeThresholdValue(metricKind, rule.above.value)})
        if (rule.above?.systolicValue) rulesArray.push({ metricKind, operator: 'Above', value: rule.above.systolicValue + '/' + rule.above.diastolicValue })
        if (rule.below?.value) rulesArray.push({ metricKind, operator: 'Below', value: this.computeThresholdValue(metricKind, rule.below.value)})
        if (rule.below?.systolicValue) rulesArray.push({ metricKind, operator: 'Below', value: rule.below.systolicValue + '/' + rule.below.diastolicValue })
        if (rule.isTrue && rule.isTrue.length > 0) rulesArray.push({ metricKind, operator: 'Equals', value: 'Possible Afib' })
        if (rule.isFalse && rule.isFalse.length > 0) rulesArray.push({ metricKind, operator: 'Equals', value: 'No Afib Detected' })
      }
      return rulesArray
    },
  },
  methods: {
    ...mapActions('patient', ['logAccess']),
    ...mapActions({
      orgUpdate: 'org/update',
      patientUpdate: 'patient/update',
    }),
    addRule() {
      this.$refs.addRule.open()
    },
    computeThresholdValue(metricKind, value) {
      // Is this duplicating logic?
      if (metricKind === 'BodyTemp') return cToF(value)
      if (metricKind === 'BodyWeight') return Math.round(kgToLbs(value) * 10) / 10
      return parseFloat(value)
    },
    async doUpdate(newRules, metricKindsUpdated) {
      if (this.orgMode) return await this.orgUpdate({ defaultPatientEventRules: newRules })

      const resp = await this.patientUpdate({ payload: { rpmAlertRules: { value: newRules } } })
      metricKindsUpdated = metricKindsUpdated.map(x => CONSTS.METRICS[x].longName).sort((a, b) => a.localeCompare(b))
      console.log('UNDO', { destination: `${metricKindsUpdated.join(', ')} alert rules updated` })
      this.logAccess({ destination: `${metricKindsUpdated.join(', ')} alert rules updated` })
      return resp
    },
    submitRule(payload) {
      const newRules = clone(this.rules)
      for (const [metricKind, newRule] of Object.entries(payload)) {
        newRules[metricKind] = Object.assign(clone(newRules[metricKind]) || {}, newRule)
      }
      this.doUpdate(newRules, Object.keys(payload))
    },
    removeRule(rule) {
      const newRules = clone(this.rules)
      newRules[rule.metricKind] = clone(this.rules[rule.metricKind])
      if (rule.operator === 'Above') delete newRules[rule.metricKind].above
      else if (rule.operator === 'Below') delete newRules[rule.metricKind].below
      else delete newRules[rule.metricKind]
      this.doUpdate(newRules, [rule.metricKind])
    },
  },
}
</script>
