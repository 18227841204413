<template>
  <v-dialog v-model="show" :max-width="options.maxWidth" scrollable>
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>
          Threshold Alert Rule
        </v-toolbar-title>
      </v-toolbar>

      <!-- Content -->
      <v-card-text>
        <v-form v-model="formComplete" ref="ruleForm">
          <v-select
            class="mt-2"
            required
            v-model="selectedMetricKind"
            :rules="defaultRules"
            :items="selectOptions"
            outlined
            label="Metric"
          ></v-select>
          <v-row no-gutters align="center" v-if="selectedMetricKind">
            <v-col v-if="selectedMetricKind !== 'Ecg'">
              <v-select
                class="mr-4"
                required
                :rules="defaultRules"
                :items="operatorOptions"
                v-model="operator"
                outlined
                label="Operator"
              />
            </v-col>
            <v-col>
              <v-row no-gutters v-if="selectedMetricKind === 'BloodPressure'">
                <v-text-field
                  v-model="systolicInput"
                  required
                  :rules="defaultRules"
                  outlined
                  type="number"
                  label="Systolic Value"
                /><v-text-field
                  required
                  v-model="diastolicInput"
                  type="number"
                  :rules="defaultRules"
                  outlined
                  label="Diastolic Value"
                />
              </v-row>
              <v-row align="center" justify="end" no-gutters v-else-if="selectedMetricKind === 'Ecg'">
                <v-radio-group v-model="afibRule" row>
                  <v-radio label="Possible Afib" value="afib"></v-radio>
                  <v-radio label="No Afib Detected" value="noAfib"></v-radio>
                </v-radio-group>
              </v-row>
              <v-row no-gutters v-else xs6>
                <v-text-field
                  v-model="valueInput"
                  required
                  :rules="defaultRules"
                  outlined
                  type="number"
                  :label="CONSTS.METRICS[selectedMetricKind].longName"
                />
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <!-- Actions -->
      <v-card-actions>
        <!-- Buttons -->
        <v-btn depressed @click="cancel()">Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn depressed dark color="primary" @click="createAlert()">Add Alert</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DialogMixin from '@/core/mixins/DialogMixin'
import CONSTS from '@/consts'

export default {
  mixins: [DialogMixin],
  data() {
    return {
      CONSTS,
      selectOptions,
      selectedMetricKind: null,
      defaultRules: [v => !!v || 'Required'],
      operator: null,
      valueInput: null,
      systolicInput: null,
      diastolicInput: null,
      formComplete: false,
      operatorOptions: ['Above', 'Below'],
      afibRule: null,
    }
  },
  methods: {
    createAlert() {
      if (!this.$refs.ruleForm.validate()) return

      let value = {}
      let payload = {}
      payload[this.selectedMetricKind] = {}
      // create the item
      if (this.selectedMetricKind === 'BloodPressure') {
        value.systolicValue = parseFloat(this.systolicInput)
        value.diastolicValue = parseFloat(this.diastolicInput)
      } else if (this.selectedMetricKind === 'BodyTemp') {
        // convert form degrees to celcius
        value.value = parseFloat(((this.valueInput - 32) * 5) / 9)
      } else if (this.selectedMetricKind === 'BodyWeight') {
        // convert form degrees to celcius
        value.value = parseFloat(this.valueInput / 2.205)
      } else if (this.selectedMetricKind === 'Ecg') {
        // convert form degrees to celcius

        if (this.afibRule === 'afib') {
          payload[this.selectedMetricKind]['isTrue'] = ['afib']
        }

        if (this.afibRule === 'noAfib') {
          payload[this.selectedMetricKind]['isFalse'] = ['afib']
        }
      } else {
        value.value = parseFloat(this.valueInput)
      }

      if (this.selectedMetricKind !== 'Ecg') {
        payload[this.selectedMetricKind][this.operator.toLowerCase()] = value
      }
      this.$emit('submit', payload)
      this.cancel()
    },
    onClose() {
      this.$refs.ruleForm.reset()
      this.$refs.ruleForm.resetValidation()
    },
  },
  mounted() {
    this.$on('close', this.onClose)
  },
}

const selectOptions = []
for (const [metricKind, details] of Object.entries(CONSTS.METRICS)) {
  selectOptions.push({ value: metricKind, text: details.longName})
}
selectOptions.sort((a, b) => a.text.localeCompare(b.text))

</script>
